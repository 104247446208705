<template>
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item>
          <b-icon
            icon="house-fill"
            scale="1.25"
            shift-v="1.25"
            aria-hidden="true"
          ></b-icon>
        </b-breadcrumb-item>
        <b-breadcrumb-item to="/recoil">Back to recoil</b-breadcrumb-item>
        <b-breadcrumb-item active>View Recoil</b-breadcrumb-item>
      </b-breadcrumb>
      <b-card title="Recoil Details" style="margin-top: 20px">
        <b-row style="margin-bottom: 15px">
          <b-col cols="4">
            <b-button variant="primary" to="/recoil">
              Back to Recoil
            </b-button>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>SO Number</strong></b-col>
          <b-col>: {{ form.orderNumber }}</b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>Line Item</strong></b-col>
          <b-col>: {{ form.lineItem }}</b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>Picklist Line</strong></b-col>
          <b-col>: {{ form.picklistLine }}</b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>Picklist No</strong></b-col>
          <b-col>: {{ form.picklistNo }}</b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>From Batch</strong></b-col>
          <b-col>: {{ form.fromBatch }}</b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>Status</strong></b-col>
          <b-col>: {{ form.status }}</b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>Status No</strong></b-col>
          <b-col>: {{ form.statusNo }}</b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>Created At</strong></b-col>
          <b-col>: {{ form.createdAt }}</b-col>
        </b-row>
      </b-card>
    </div>
  </template>
  <script>
  import { dateFormat, userAccess, viewAccess } from "@/utils/utils.js";
  
  export default {
    props: ["id"],
    data() {
      return {
        form: {
          orderNumber: "",
          requestNumber: "",
          receiver: "",
          createdAt: "",
          item: [],
          props: "",
        },
      };
    },
    methods: {

    },
    
    mounted() {
      this.$store.dispatch('picklist/getPicklistId', {id : this.id})
        .then((res) => {
          var data = this.$store.getters["picklist/getPicklistId"]

          var newCreated = dateFormat(data.createdAt).split(",")
          var newUpdated = dateFormat(data.updatedAt).split(",")

          this.form.orderNumber = data.orderNumber;
          this.form.status = data.props.status;
          this.form.statusNo = data.props.statusNo;
          this.form.fromBatch = data.fromBatch;
          this.form.lineItem = data.lineItem;
          this.form.createdAt = newCreated[0] + newCreated[1].replace(/\./g, ':'),  
          this.form.picklistLine = data.picklistLine;
          this.form.picklistNo = data.picklistNo;
        })
        .catch((err) => console.log({ err }));
    },
  };
  </script>